import { GIProgress } from './genshin-progress';
import './App.css';
import { useEffect, useState } from 'react';
import { Divider } from 'antd';
import Aegis from 'aegis-web-sdk';


function App() {
  const [num, setNum] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [wideScreen, setWideScreen] = useState(window.innerWidth >= 720);
  useEffect(() => {
    setTimeout(() => setNum(6), 500);
    setTimeout(() => setNum(20), 1000);
    setTimeout(() => setNum(25), 1200);
    setTimeout(() => setNum(40), 1400);
    setTimeout(() => setNum(50), 1500);
    setTimeout(() => setNum(70), 1800);
    setTimeout(() => setNum(90), 2000);
    setTimeout(() => setNum(93), 2100);
    setTimeout(() => setNum(100), 4000);
    setTimeout(() => setOpacity(100), 5100);
    setTimeout(() => setOpacity(0), 5300);
    setTimeout(() => window.location.href = 'https://www.nnnu.edu.cn', 5700);
    window.onresize = function () {
      setWideScreen(window.innerWidth >= 720);
    }
  }, []);
  const aegis = new Aegis({
    id: 'QlWyV8Np04vdN15Yok', // 上报 id
    uin: '广西原神大学.com', // 用户唯一 ID（可选）
    reportApiSpeed: true, // 接口测速
    reportAssetSpeed: true, // 静态资源测速
    spa: true, // spa 应用页面跳转的时候开启 pv 计算
    hostUrl: 'https://rumt-zh.com'
  });

  return (
    <div className="App" style={{
      opacity,
    }}>
      <img src={require('./liyue.png')} style={{ position: 'fixed', bottom: '50%', width: 100, objectFit: 'scale-down', }} />

      <div style={{
        width: '100%',
        textAlign: 'center'
      }}>
        <p className='desc' style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>南宁师范大学</p>
        <div className='desc' style={{ textAlign: 'center', paddingInline: '3vw', fontSize: '0.9rem' }}>
          全国深化创新创业教育改革示范高校，全国民族团结进步先进单位，国家级语言文字规范化示范学校，全国学校艺术教育工作先进单位
          <br />Powered by React | <a style={{ color: 'gray' }} href='https://www.arsrna.com/'>Ar-Sr-Na</a>
        </div>
        <Divider>
          <GIProgress num={num} />
        </Divider>

      </div>
    </div>
  );
}


export default App;
